import axios from 'axios';
import { basePath } from './config';

export const createOrUpdateUser = async (authtoken) => {
  return await axios.post(
    `${basePath}/create-or-update-user`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentUser = async (authtoken) => {
  return await axios.post(
    `${basePath}/current-user`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentAdmin = async (authtoken) => {
  return await axios.post(
    `${basePath}/current-admin`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};
