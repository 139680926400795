import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyBYqt9YtxeAvsKUQwELS2arbxG42pfkGHM',
  authDomain: 'reconciliemos.firebaseapp.com',
  databaseURL: 'https://reconciliemos-default-rtdb.firebaseio.com',
  projectId: 'reconciliemos',
  storageBucket: 'reconciliemos.appspot.com',
  messagingSenderId: '938770401374',
  appId: '1:938770401374:web:6215861b6669f8f1700ccf',
  measurementId: 'G-0WTVDYHS19',
};
// initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// export default firebase;
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
